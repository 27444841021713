import { Trade } from '@pancakeswap-libs/sdk'
import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import { Text } from 'rebass'
import { Flex } from '@impossiblefinance/uikit'
import { ButtonSecondary } from '../../components/Button'
import Column, { AutoColumn } from '../../components/Column'
import Row, { RowBetween } from '../../components/Row'
import useStaxPrice from '../../hooks/useStaxPrice'
import useUnstake from '../../hooks/useUnstake'
import { Farm } from '../../state/types'
import { getBalanceNumber } from '../../utils/formatBalance'
import ClosableCard from './ClosableCard'
import Modal from '../../components/Modal'
import { ConfirmationPendingContent } from '../../components/TransactionConfirmationModal'

type StakingCardType = {
  farms: Farm[]
}

type StakingItemType = {
  farm: Farm
  staxPrice: Trade
}

const StakingItem: React.FC<StakingItemType> = ({ farm, staxPrice }) => {
  const [loading, setLoading] = useState(false)
  const { onUnstake } = useUnstake(farm.pid, () => setLoading(false))

  return (
    <>
      <Modal isOpen={loading} onDismiss={() => {}} maxHeight={90}>
        <ConfirmationPendingContent onDismiss={() => {}} pendingText="" showClose={false} />
      </Modal>
      <ClosableCard
        title={`${farm.lpSymbol} ${farm.isPancake ? '(Pancake)' : ''}`}
        icon={farm.tokenSymbol}
        icon2={farm.quoteTokenSymbol}
      >
        <AutoColumn gap="12px" style={{ width: '100%' }}>
          <RowBetween>
            <Column>{farm.lpSymbol} staked</Column>
            <Column>
              <Text fontSize="18px" fontWeight="600">
                {farm.userData && getBalanceNumber(farm.userData.stakedBalance).toFixed(3)}
              </Text>
            </Column>
          </RowBetween>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex flex="3">STAX earned</Flex>
            <Column>
              <Flex alignItems="flex-end" justifyContent="flex-end">
                <Text fontSize="18px" fontWeight="600">
                  <Column>{farm.userData && getBalanceNumber(farm.userData.earnings).toFixed(3)}</Column>
                </Text>
              </Flex>
              <Row>
                <Text fontSize="13px">
                  <Column>
                    ~
                    {farm.userData &&
                      staxPrice &&
                      getBalanceNumber(
                        farm.userData.earnings.times(new BigNumber(staxPrice.executionPrice.toSignificant(4)))
                      ).toFixed(3)}{' '}
                    USD
                  </Column>
                </Text>
              </Row>
            </Column>
          </Flex>
          <Row>
            <ButtonSecondary
              width="48%"
              marginTop="4px"
              onClick={() => {
                setLoading(true)
                onUnstake(new BigNumber(farm.userData.stakedBalance.toFixed(8)))
              }}
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Unstake LP'}
            </ButtonSecondary>
          </Row>
        </AutoColumn>
      </ClosableCard>
    </>
  )
}

const StakingCard: React.FC<StakingCardType> = ({ farms }) => {
  const price = useStaxPrice()
  return (
    <AutoColumn gap="8px">
      {farms.map(farm => (
        <StakingItem farm={farm} staxPrice={price} />
      ))}
    </AutoColumn>
  )
}

export default StakingCard
