import tokenList from "../constants/tokenList";
import { tryParseAmount } from "../state/swap/hooks";
import { useCurrency } from "./Tokens";
import { useTradeExactIn } from "./Trades";

const useStaxPrice = () => {
  const stax = useCurrency(tokenList.STAX.address) ?? undefined
  const busd = useCurrency(tokenList.BUSD.address) ?? undefined
  const parsedAmount = tryParseAmount("1", stax)
  return useTradeExactIn(parsedAmount, busd)
}

export default useStaxPrice;