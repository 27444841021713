import { MenuEntry } from '@impossiblefinance/uikit'

const config: MenuEntry[] = [
  {
    label: 'Swap',
    href: `${process.env.REACT_APP_DOMAIN}/#/swap`,
  },
  {
    label: 'Liquidity',
    href: `${process.env.REACT_APP_DOMAIN}/#/pool`,
  },
  {
    label: 'Stake',
    href: `${process.env.REACT_APP_FARMS_DOMAIN}/farms`,
  },
  {
    label: 'Migration',
    href: `/migrate`,
    isSidebar: true,
    isNew: true,
  },
  {
    label: 'FAQ',
    href: 'https://impossible.freshdesk.com/support/home',
    isSidebar: true,
  }
]

export default config
