import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Text } from 'rebass'

import { AutoColumn } from '../../components/Column'
import Row, { AutoRow } from '../../components/Row'
import CoinLogo from '../../components/pancake/CoinLogo'
import { HoverCard } from '../../components/PositionCard'
import { Flex } from '@impossiblefinance/uikit'

type CloseableCardType = {
  icon: string
  icon2: string
  title: string
  children: React.ReactNode
}

const ClosableCard: React.FC<CloseableCardType> = ({ icon, title, icon2, children }) => {
  const theme = useTheme()
  const [isClose, setClose] = useState(false)
  const close = () => {
    setClose(!isClose)
  }
  return (
    <HoverCard backgroundColor={'white'}>
      <AutoColumn>
        <AutoRow justify="space-between">
          <Flex alignItems="center">
            <CoinLogo size="24px" srcs={[`/images/coins/${icon ?? 'token'}.png`]} alt={`${icon} logo`} />
            <CoinLogo size="24px" srcs={[`/images/coins/${icon2 ?? 'token'}.png`]} alt={`${icon2} logo`} />
            <Text color={theme.colors.text2} fontWeight="500" fontSize={20} marginLeft="8px">
              {title}
            </Text>
          </Flex>

          <div onClick={close}>{isClose ? <ChevronDown /> : <ChevronUp />}</div>
        </AutoRow>
        {!isClose && <Row marginTop="14px">{children}</Row>}
      </AutoColumn>
    </HoverCard>
  )
}

export default ClosableCard
