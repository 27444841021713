import { Flex } from '@impossiblefinance/uikit'
import { TokenAmount } from '@pancakeswap-libs/sdk'
import React, { useCallback, useState } from 'react'
import { Text } from 'rebass'
import { useTheme } from 'styled-components'
import { ButtonSecondary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import Modal from '../../components/Modal'
import CoinLogo from '../../components/pancake/CoinLogo'
import Row from '../../components/Row'
import { ConfirmationPendingContent } from '../../components/TransactionConfirmationModal'
import { useIFGovernanceContract, useSTAXGovernanceContract } from '../../hooks/useContract'
import useStaxPrice from '../../hooks/useStaxPrice'
import ConfirmationIFContent, { CongratulationContent } from './ConfirmationIFContent'

type IFCardType = {
  staxBalance?: TokenAmount
  disabled: boolean
}
const IFCard: React.FC<IFCardType> = ({ staxBalance, disabled }) => {
  const ifContract = useIFGovernanceContract()
  const staxContract = useSTAXGovernanceContract()
  const theme = useTheme()
  const price = useStaxPrice()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [congratulationOpen, setCongratsOpen] = useState(false)

  const closeConfirm = () => {
    setConfirmOpen(false)
  }
  const openConfirm = () => {
    setConfirmOpen(true)
  }

  const openLoading = () => {
    setLoadingOpen(true)
  }
  const closeLoading = () => {
    setLoadingOpen(false)
  }

  const openCongrats = () => {
    setCongratsOpen(true)
  }
  const closeCongrats = () => {
    setCongratsOpen(false)
  }

  const migrate = useCallback(async () => {
    if (staxContract && ifContract && staxBalance) {
      const nominal = staxBalance.raw.toString()
      try {
        let approval = await staxContract.approve(ifContract.address, nominal)
        await approval.wait()
        let migrate = await ifContract.staxMigrate(nominal)
        await migrate.wait()
        closeLoading()
        openCongrats()
      } catch (err) {
        console.log(err)
        closeLoading()
      }
    }
  }, [staxContract, ifContract, staxBalance])

  const onConfirm = () => {
    closeConfirm()
    openLoading()
    migrate()
  }

  if (!staxBalance) {
    return <Text>Loading...</Text>
  }
  if (staxBalance.toSignificant(4) === '0') {
    return (
      <Text color="#A6A8AA" fontSize="14px">
        No STAX tokens found.
      </Text>
    )
  }
  return (
    <>
      <Modal isOpen={loadingOpen} onDismiss={() => {}} maxHeight={90}>
        <ConfirmationPendingContent onDismiss={() => {}} pendingText="" showClose={false} />
      </Modal>
      <CongratulationContent isOpen={congratulationOpen} onDismiss={closeCongrats} />
      <ConfirmationIFContent
        stax={staxBalance.toFixed(4)}
        isOpen={confirmOpen}
        onDismiss={closeConfirm}
        onConfirm={onConfirm}
      />
      <AutoColumn gap="32px">
        <Flex>
          <CoinLogo size="24px" srcs={[`/images/coins/STAX.png`]} alt={`STAX logo`} />
          <Text marginLeft="16px" fontWeight={500} fontSize="18px">
            {staxBalance?.toSignificant(4)}
          </Text>
          <Text marginLeft="24px" fontSize="14px" color={theme.colors.text2}>
            ~{staxBalance && staxBalance.multiply(price.executionPrice).toSignificant(4)} USD
          </Text>
        </Flex>
        <Row>
          {disabled ? (
            <Text fontSize="14px" color="#A6A8AA">
              Unstake STAX LP tokens to proceed migration.
            </Text>
          ) : (
            <ButtonSecondary width="48%" onClick={openConfirm}>
              Migrate
            </ButtonSecondary>
          )}
        </Row>
      </AutoColumn>
    </>
  )
}

export default IFCard
