import { Box, Flex } from '@impossiblefinance/uikit'
import React from 'react'
import { Text } from 'rebass'
import { ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import Modal from '../../components/Modal'
import CoinLogo from '../../components/pancake/CoinLogo'
import { RowBetween } from '../../components/Row'
import { CloseIcon } from '../../components/Shared'

export default function ConfirmationIFContent({
  isOpen,
  onDismiss,
  onConfirm,
  stax
}: {
  isOpen: boolean
  onDismiss: () => void
  onConfirm: () => void
  stax: string
}) {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      <Box padding="24px" width="100%">
        <AutoColumn gap="32px">
          <RowBetween>
            <Text fontWeight={500} fontSize={20}>
              Confirm Migrate
            </Text>
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
          <AutoColumn gap="21px">
            <RowBetween>
              <Flex alignItems="center">
                <CoinLogo size="24px" srcs={[`/images/coins/STAX.png`]} alt={`STAX logo`} />
                <Text color="#3B4346" fontSize="24px" paddingLeft="12px">
                  {stax}
                </Text>
              </Flex>
              <Flex>
                <Text color="#3B4346" fontSize="18px" fontWeight={500}>
                  STAX
                </Text>
              </Flex>
            </RowBetween>
            <RowBetween>
              <CoinLogo size="20px" srcs={[`/images/Arrow.svg`]} alt={`Arrow`} />
            </RowBetween>
            <RowBetween>
              <Flex alignItems="center">
                <CoinLogo size="24px" srcs={[`/images/coins/IF.svg`]} alt={`IF logo`} />
                <Text color="#3B4346" fontSize="24px" paddingLeft="12px">
                  {stax}
                </Text>
              </Flex>
              <Flex>
                <Text color="#3B4346" fontSize="18px" fontWeight={500}>
                  IF
                </Text>
              </Flex>
            </RowBetween>
            <Flex>
              <Text color="#3B4346" fontSize="16px">
                You are migrating {stax} STAX to IF. <br />
                Coverting STAX to IF is 1 : 1.
              </Text>
            </Flex>
          </AutoColumn>
          <Flex justifyContent="center">
            <ButtonPrimary onClick={onConfirm}>Confirm</ButtonPrimary>
          </Flex>
        </AutoColumn>
      </Box>
    </Modal>
  )
}

export function CongratulationContent({ isOpen, onDismiss }: { isOpen: boolean; onDismiss: () => void }) {
  const redirectToPool = () => {
    window.location.replace(`${process.env.REACT_APP_DOMAIN}/#/pool`)
  }
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      <Box padding="24px" width="100%">
        <AutoColumn gap="32px">
          <RowBetween>
            <Text fontWeight={500} fontSize={20}>
              Congratulations
            </Text>
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
          <AutoColumn gap="21px">
            <Flex justifyContent="center">
              <Flex>
                <img src="/images/congrats.svg" width="75px" alt="cgts" />
              </Flex>
            </Flex>
            <Flex>
              <Text color="#3B4346" fontSize="16px" paddingX="24px">
                The migration is almost done! <br />
                You can now add liquidity and stake to earn IF!
              </Text>
            </Flex>
          </AutoColumn>
          <Flex justifyContent="center">
            <ButtonPrimary onClick={redirectToPool}>Add Liquidity</ButtonPrimary>
          </Flex>
        </AutoColumn>
      </Box>
    </Modal>
  )
}
