import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
// import { TransactionResponse } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { useDispatch } from 'react-redux'
import { fetchFarmUserDataAsync } from '../state/actions'
import { useMasterChefContract } from './useContract'

export const unstake = async (masterChefContract, pid, amount, account) => {
  return masterChefContract.withdraw(pid, amount.toString(), {
    gasLimit: 200000
  })
}

const useUnstake = (pid: number, onCompleted: Function) => {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const masterChefContract = useMasterChefContract()

  const handleUnstake = useCallback(
    async (amount: BigNumber) => {
      try {
        const tx = await unstake(masterChefContract, pid, amount, account)
        await tx.wait()
        dispatch(fetchFarmUserDataAsync(account))
        console.info(tx.hash)
        onCompleted()
      } catch (err) {
        console.error(err)
        onCompleted()
      }
      
    },
    [account, dispatch, masterChefContract, pid, onCompleted]
  )

  return { onUnstake: handleUnstake }
}

export default useUnstake
