import React, { useMemo } from 'react'
import Row from '../../components/Row'
import styled from 'styled-components'
import useAuth from '../../hooks/useAuth'
import { useWalletModal } from '@impossiblefinance/uikit'
import { StepsCard } from '../../components/StepsCard'
import { useActiveWeb3React } from '../../hooks'
import { ButtonLight } from '../../components/Button'
import { TranslateString } from '../../utils/translateTextHelpers'
import { useFarms } from './useFarms'
import StakingCard from './StakingCard'
import { Text } from 'rebass'
import { usePairs } from '../../data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { useTokenBalance, useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { Pair, Token } from '@pancakeswap-libs/sdk'
import PoolCard from './PoolCard'
import IFCard from './IFCard'
import { ExternalLink } from '../../components/Shared'

export const HeaderWrapper = styled.div`
  width: 100%;
  padding: 2rem 10rem;
  background: white;
  margin-top: -50px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 2rem 3rem;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 2rem;
    width: calc(100% + 32px);
    margin-top: 0;
  `};
`

export const HeaderTitle = styled.div`
  font-size: 28px;
  line-height: 34px;
`

export const HeaderDescription = styled.div`
  margin-top: 8px;
  font-size: 14px;
  line-height: 18px;
`

export const MigrationCardWrapper = styled.div`
  margin-top: 24px;
  padding: 2rem 18rem;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 2rem 8rem;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 2rem 8rem;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0rem;
  `};
`

export const StepDivider = styled.div`
  height: 32px;
`

export default function Migration() {
  const { login, logout } = useAuth()
  const { account } = useActiveWeb3React()
  const { onPresentConnectModal } = useWalletModal(login, logout)

  // staking
  const farms = useFarms(account)
  const filteredFarms = farms.filter(farm => farm.userData && farm.userData.stakedBalance.comparedTo(0) === 1)
  const isFirstStepDone = filteredFarms.length === 0

  // LP
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )
  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  const secondStepDone = !v2IsLoading && allV2PairsWithLiquidity.length === 0

  const token = new Token(56, '0x0da6ed8b13214ff28e9ca979dd37439e8a88f6c4', 18, 'STAX')
  const staxBalance = useTokenBalance(account ?? undefined, token)
  const thirdStepDone = staxBalance && staxBalance.toSignificant(4) === '0'
  const renderNotConnected = () => {
    return (
      <>
        <div>
          <Text color="#A6A8AA">Connect wallet to view your staked STAX LP tokens.</Text>
        </div>
        <ButtonLight onClick={onPresentConnectModal}>{TranslateString(292, 'Connect Wallet')}</ButtonLight>
      </>
    )
  }

  const STEPS = [
    {
      title: 'Unstake STAX LP tokens',
      stepTitle: 'Staked STAX LP tokens ',
      description: '',
      status: !account ? 'DISABLED' : !isFirstStepDone ? 'ACTIVE' : 'DONE',
      step: 1,
      imgSrc: '/images/Unstake.svg',
      action: () => (
        <>
          {!account ? (
            renderNotConnected()
          ) : !isFirstStepDone ? (
            <StakingCard farms={filteredFarms} />
          ) : (
            <Text color="#A6A8AA" fontSize="14px">
              You’ve completed this step. No STAX LP tokens to unstake.
            </Text>
          )}
        </>
      )
    },
    {
      title: 'Remove STAX Liquidity',
      stepTitle: 'Unstaked STAX LP tokens in your wallet ',
      description: '',
      status: !account || !isFirstStepDone ? 'DISABLED' : isFirstStepDone && !secondStepDone ? 'ACTIVE' : 'DONE',
      step: 2,
      imgSrc: '/images/RemoveLP.svg',
      action: () => (
        <>
          {!account ? (
            renderNotConnected()
          ) : secondStepDone ? (
            isFirstStepDone ? (
              <Text color="#A6A8AA" fontSize="14px">
                You’ve completed this step. No unstaked STAX LP tokens to remove liquidity from.
              </Text>
            ) : (
              <Text color="#A6A8AA" fontSize="14px">
                No unstaked STAX LP tokens found.
              </Text>
            )
          ) : (
            <PoolCard isLoading={v2IsLoading} liquidity={allV2PairsWithLiquidity} disabled={!isFirstStepDone} />
          )}
        </>
      )
    },
    {
      title: 'Migrate to IF',
      stepTitle: 'STAX tokens in your wallet',
      description:
        'You will receive IF tokens in your wallet once the transaction has been confirmed. STAX is converted to IF at a 1 : 1 ratio.',
      status: !account || !isFirstStepDone || !secondStepDone ? 'DISABLED' : thirdStepDone ? 'DONE' : 'ACTIVE',
      step: 3,
      imgSrc: '/images/Migrate.svg',
      action: () => (
        <>
          {!account ? (
            renderNotConnected()
          ) : isFirstStepDone && secondStepDone && thirdStepDone ? (
            <Text color="#A6A8AA" fontSize="14px">
              You’ve completed this step. No STAX tokens found.
            </Text>
          ) : (
            <IFCard staxBalance={staxBalance} disabled={!isFirstStepDone || !secondStepDone} />
          )}
        </>
      )
    }
  ]

  return (
    <>
      <HeaderWrapper>
        <Row>
          <HeaderTitle>Migration</HeaderTitle>
        </Row>
        <Row>
          <HeaderDescription>
            <Row>
              Impossible.Finance is migrating STAX to IF tokens (1 : 1), and also upgrading our smart contracts for new
              features. Staked rewards from STAX LP tokens will stop.
            </Row>
            <Row>
              Please follow the instructions below to complete the migration, then add liquidity to receive IF LP tokens
              and stake them to continue earning rewards.
            </Row>
          </HeaderDescription>
        </Row>
      </HeaderWrapper>
      <MigrationCardWrapper>
        {STEPS.map(step => {
          return (
            <>
              <StepsCard {...step}>{step.action()}</StepsCard>
              <StepDivider />
            </>
          )
        })}
        Once you migrated to IF tokens, you can start to{' '}
        <ExternalLink isInternal href={`${process.env.REACT_APP_DOMAIN}/#/pool`}>
          Add Liquidity
        </ExternalLink>{' '}
        and{' '}
        <ExternalLink isInternal href={`${process.env.REACT_APP_FARMS_DOMAIN}/farms`}>
          {TranslateString(108, 'Stake')}
        </ExternalLink>
      </MigrationCardWrapper>
    </>
  )
}
