import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFarmUserDataAsync } from '../../state/actions'
import { State, Farm } from '../../state/types'

export const useFarms = (account): Farm[] => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchFarmUserDataAsync(account))
    }
  }, [account, dispatch])

  const farms = useSelector((state: State) => state.farms.data)
  return farms
}
